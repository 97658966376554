.score-card {
  background-color: #bbada0;
  color: #fff;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 4rem;
  min-width: 4rem;
  padding: 0px 1rem;
  border-radius: 5px;
  position: relative;
}

.score-label {
  text-transform: uppercase;
  color: #eee4da;
}

.score-addition {
  position: absolute;
  top: 2rem;
  color: #776e65;
  opacity: 0;
}

@keyframes slide-off {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(0px, -100%);
    display: none;
  }
}
