:root {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

/* reset */

body, p, a, ul, li {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

/* base styles */

body {
  background-color: #faf8ef;
  color: #776e65;
  overflow: hidden;
  height: 100vh;
}

.App {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  height: 100vh;
}

@media screen and (min-width: 560px) {
  :root {
    font-size: 18px;
  }
  @media screen and (max-height: 600px) {
    :root {
      font-size: 16px;
    }
  }
  @media screen and (min-height: 600px) {
    :root {
      font-size: 16px;
    }
  }
  @media screen and (min-height: 700px) {
    :root {
      font-size: 18px;
    }
  }
  @media screen and (min-height: 800px) {
    :root {
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 960px) and (min-height: 800px) {
  :root {
    font-size: 20px;
  }
}