.overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 2rem;
  text-align: center;
  color: #746d64;
  overflow: hidden;
}

.overlay-background {
  backdrop-filter: blur(16px);
  background-color: rgba(255, 255, 255, 0.4);
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
}

.overlay-contents {
  z-index: 1;
}

.overlay h1 {
  margin: 0px;
}

.overlay p {
  margin: 0px;
  font-weight: bold;
}
