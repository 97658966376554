#board {
  background-color: #bbada0;
  border-radius: 15px;
  padding: var(--tile-margin);
  margin: 2rem 0;
}

#board tr {
  display: flex;
}

#board-background {
  height: 100%;
  width: 100%;
  padding: 0px;
  border-collapse: collapse;
}

#board-background td {
  width: var(--tile-size);
  height: var(--tile-size);
  border-radius: 5px;
  border: none;
  padding: 0px;
  margin: var(--tile-margin);
  background-color: #cdc1b4;
}

/* height breakpoints */

@media screen and (max-height: 600px) {
  #board {
    margin: 0.5rem 0;
  }
}

/* 
@media screen and (min-height: 960px) {
  :root {
    font-size: 20px;
  }
}

@media screen and (min-height: 1200px) {
  :root {
    font-size: 22px;
  }
} */